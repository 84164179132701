import React from 'react';
import { Card, CardContent, Typography, Box } from '@mui/material';
import { Bounce } from 'react-awesome-reveal';
import Header from '../blocks/Header';
import Footer from './FooterSection';  // Add this line to import your Footer

const Services = () => {
  return (
    <Box sx={{
      minHeight: '100vh',
      textAlign: 'center', 
      padding: '20px',
      backgroundColor: '#ffffff',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
    }}>
      <Box sx={{
        position: ['relative', 'fixed'],
        top: 0,
        right: 0,
        left: 0,
        zIndex: 2,
      }}>
        <Header />
      </Box>
      <Box sx={{
        position: 'absolute',
        top: ['120px', '90px'],
        right: 0,
        left: 0,
        zIndex: 1,
        backgroundColor: '#808080',
        padding: '10px',
      }}>
        <Bounce>
          <Typography variant="h6" align="center" sx={{ color: 'white', textShadow: '2px 2px 0px #000' }}>
            Our Services
          </Typography>
        </Bounce>
      </Box>
      <Box sx={{
        display: 'flex',
        flexDirection: ['column-reverse', 'row'],
        justifyContent: 'center',
        alignItems: 'center',
        gap: '20px',
        p: '20px',
        mt: ['350px', '180px'],
        mb: ['20px', '120px'],
        flex: 1,
      }}>
        <Box sx={{ 
            flex: 1, 
            overflow: 'auto', 
            pr: 2, 
            pb: 2, 
        }}>
          <Typography 
            variant="body1"
            sx={{ 
              fontStyle: 'italic',
              fontWeight: 'bold',
              color: 'black',
            }}
          >
            At Xtreme Woodworking we offer a range of services including custom designs for kitchens, bathroom vanities, entertainment centers, garage cabinets, closets, wall beds, wall units, sales offices, reception areas, and architectural millwork. We pride ourselves in using the highest quality materials including solid hardwoods, exotic veneers, and textured laminates.
          </Typography>
        </Box>
        <Box sx={{ 
            flex: 1, 
            overflow: 'auto', 
            pl: 2, 
            pb: 2, 
            display: 'flex', 
            flexDirection: 'column', 
            gap: '20px' 
        }}>
          <Card sx={{ borderRadius: '15px', overflow: 'hidden', }}>
            <CardContent>
              <Typography variant="h5" component="div">
                What We Offer
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Custom Designs
                <br />
                Kitchens - Bathroom Vanities
                <br />
                Entertainment Centers - Garage Cabinets
                <br />
                Closets - Wall Beds
                <br />
                Wall Units - Sales Offices
                <br />
                Reception Areas - Architectural Millwork
              </Typography>
            </CardContent>
          </Card>
          <Card sx={{ borderRadius: '15px', overflow: 'hidden', }}>
            <CardContent>
              <Typography variant="h5" component="div">
                Materials We Use
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Solid Hardwoods
                <br />
                Exotic Veneers
                <br />
                Textured Laminates
              </Typography>
            </CardContent>
          </Card>
        </Box>
      </Box>
      <Footer />  {/* Add this line to include your Footer */}
    </Box>
  );
};

export default Services;

